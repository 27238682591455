import xw from 'xwind';
import styled from '@emotion/styled';
import { motion } from 'framer-motion';

export { DeleteIcon, WarningIcon, LoadingIcon } from '@/components/icons';

export {
  Button,
  ButtonGray,
  ButtonGrayOutline,
  ButtonNegative,
} from '@/components/buttons';

export const ContentWrapper = styled(motion.div)`
  ${xw`
    relative
    flex
    flex-col
    items-center
    bg-gray-100
    rounded-2xl
    shadow-lg
    outline-none
    focus:outline-none
  `}
  padding: 100px 50px 50px;
  width: min(100vw, 350px);
  min-height: min(100vh, 450px);
`;

export const FeaturedWrapper = styled.div`
  margin-bottom: 30px;
`;

export const Message = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 15px;
  text-align: center;
  margin-bottom: 50px;
`;

export const MessageHeader = styled.div(xw`text-2xl`);

export const MessageBody = styled.div``;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
`;
